const apiList = [
  // {
  //   name: "authenticate",
  //   argNames: ["authKey"],
  //   argTypes: ["str"],
  //   returnType: "Dict",
  //   description: "Authenticate with the Universal Shell using an authentication key.",
  //   example: { authKey: "your_auth_key_here" }
  // },
  {
    name: "run_cmd",
    argNames: ["command", "timeout"],
    argTypes: ["str", "Optional[float]"],
    returnType: "Dict",
    description: "Run a single command with an optional timeout.",
    example: { command: "\"ls -l\"", timeout: 10 }
  },
  // {
  //   name: "run_cmds",
  //   argNames: ["commands", "timeout"],
  //   argTypes: ["List[str]", "Optional[float]"],
  //   returnType: "Dict",
  //   description: "Run multiple commands with an optional timeout.",
  //   example: { commands: ["ls", "pwd"], timeout: 10 }
  // },
  {
    name: "run_script",
    argNames: ["script_path", "timeout"],
    argTypes: ["str", "Optional[float]"],
    returnType: "Dict",
    description: "Run a script file with an optional timeout.",
    example: { script_path: "/path/to/script.sh", timeout: 10 }
  },
  {
    name: "reboot",
    argNames: ["timeout"],
    argTypes: ["float"],
    returnType: "Dict",
    description: "Reboot the system with a specified timeout.",
    example: { timeout: 60 }
  },
  {
    name: "start_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Start a specified service.",
    example: { service_name: "nginx" }
  },
  {
    name: "status_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Get the status of a specified service.",
    example: { service_name: "nginx" }
  },
  {
    name: "stop_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Stop a specified service.",
    example: { service_name: "nginx" }
  },
  {
    name: "restart_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Restart a specified service.",
    example: { service_name: "nginx" }
  },
  {
    name: "upkeep_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Start a service if it is in a DEAD state, otherwise do nothing.",
    example: { service_name: "nginx" }
  },
  {
    name: "create_service",
    argNames: ["service_name", "command"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Create a new service with a specified command.",
    example: { service_name: "my_service", command: "\"/path/to/my/app.bin\"" }
  },
  {
    name: "create_onboot_service",
    argNames: ["service_name", "command"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Create a new service with a specified command which automatically starts on-boot.",
    example: { service_name: "my_service", command: "\"/path/to/my/app.bin\"" }
  },
  {
    name: "create_systemd_service",
    argNames: ["service_name", "unit_file"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Create a new systemd service with a specified unit (configuration) file on local machine. Only works on linux (managed) systems.",
    example: { service_name: "systemd_service", command: "\"/path/to/my/systemd_service.service\"" }
  },
  {
    name: "delete_service",
    argNames: ["service_name"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Delete a specified service.",
    example: { service_name: "my_service" }
  },
  {
    name: "list_services",
    argNames: [],
    argTypes: [],
    returnType: "Dict",
    description: "List all available services.",
    example: {}
  },
  {
    name: "list_processes",
    argNames: [],
    argTypes: [],
    returnType: "Dict",
    description: "List all running processes.",
    example: {}
  },
  {
    name: "search_processes",
    argNames: ["query"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Search for running processes matching a specified query.",
    example: { query: "chrome" }
  },
  {
    name: "start_process",
    argNames: ["command"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Start a new process with the specified command.",
    example: { command: "node server.js" }
  },
  {
    name: "select_process",
    argNames: ["process_id"],
    argTypes: ["int"],
    returnType: "Dict",
    description: "Select a process using its ID.",
    example: { process_id: 1234 }
  },
  {
    name: "kill_process",
    argNames: ["process_id"],
    argTypes: ["int"],
    returnType: "Dict",
    description: "Kill a process using its ID.",
    example: { process_id: 1234 }
  },
  // {
  //   name: "read_file_chunk",
  //   argNames: ["file_path", "chunk_no", "chunk_size"],
  //   argTypes: ["str", "int", "int"],
  //   returnType: "Dict",
  //   description: "Read a chunk of data from a file.",
  //   example: { file_path: "/path/to/file.txt", chunk_no: 1, chunk_size: 1024 }
  // },
  // {
  //   name: "append_file_chunk",
  //   argNames: ["file_path", "chunk_no", "file_chunk"],
  //   argTypes: ["str", "int", "str"],
  //   returnType: "Dict",
  //   description: "Append a chunk of data to a file.",
  //   example: { file_path: "/path/to/file.txt", chunk_no: 1, file_chunk: "..." }
  // },
  {
    name: "list_dir",
    argNames: ["dir_path"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "List all files and directories in a specified directory.",
    example: { dir_path: "/path/to/directory" }
  },
  {
    name: "remote_copy_file",
    argNames: ["local_path", "remote_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a file from the local system to a remote system.",
    example: { local_path: "/path/to/local/file.txt", remote_path: "/path/to/remote/file.txt" }
  },
  {
    name: "remote_copy_dir",
    argNames: ["local_path", "remote_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a directory from the local system to a remote system.",
    example: { local_path: "/path/to/local/directory", remote_path: "/path/to/remote/directory" }
  },
  {
    name: "local_copy_file",
    argNames: ["local_path", "remote_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a file from a remote system to the local system.",
    example: { local_path: "/path/to/local/file.txt", remote_path: "/path/to/remote/file.txt" }
  },
  {
    name: "local_copy_dir",
    argNames: ["local_path", "remote_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a directory from a remote system to the local system.",
    example: { local_path: "/path/to/local/directory", remote_path: "/path/to/remote/directory" }
  },
  {
    name: "mk_file",
    argNames: ["file_path"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Create a new file.",
    example: { file_path: "/path/to/new_file.txt" }
  },
  {
    name: "mk_dir",
    argNames: ["dir_path"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Create a new directory.",
    example: { dir_path: "/path/to/new_directory" }
  },
  {
    name: "copy_file",
    argNames: ["source_path", "destination_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a file from one location to another.",
    example: { source_path: "/path/to/source/file.txt", destination_path: "/path/to/destination/file.txt" }
  },
  {
    name: "copy_dir",
    argNames: ["source_path", "destination_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Copy a directory from one location to another.",
    example: { source_path: "/path/to/source/directory", destination_path: "/path/to/destination/directory" }
  },
  {
    name: "mv_file",
    argNames: ["source_path", "destination_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Move a file from one location to another.",
    example: { source_path: "/path/to/source/file.txt", destination_path: "/path/to/destination/file.txt" }
  },
  {
    name: "mv_dir",
    argNames: ["source_path", "destination_path"],
    argTypes: ["str", "str"],
    returnType: "Dict",
    description: "Move a directory from one location to another.",
    example: { source_path: "/path/to/source/directory", destination_path: "/path/to/destination/directory" }
  },
  {
    name: "rm_file",
    argNames: ["file_path"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Remove a file.",
    example: { file_path: "/path/to/file.txt" }
  },
  {
    name: "rm_dir",
    argNames: ["dir_path"],
    argTypes: ["str"],
    returnType: "Dict",
    description: "Remove a directory.",
    example: { dir_path: "/path/to/directory" }
  }
];

export default apiList;
