import React, { useState } from 'react';

import Affiliations from './homepage/Affiliations';
import FadeInView from '../helper/FadeInView';
import CompanyLogo from '../helper/CompanyLogo';

import './SubmitInquiry.css'; // Import the CSS file for styling

const SubmitInquiry = ({ apiClient }) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        messageContent: ''
    });
    const [submitting, setSubmitting] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            const accessToken = String(localStorage.getItem('accessToken'));
            const response = await apiClient.submit_inquiry(
              accessToken,
              formData.firstName,
              formData.lastName,
              formData.emailAddress,
              formData.phoneNumber,
              formData.messageContent,
            );
            setResponseMessage(response.data.message);
            setFormData({
                firstName: '',
                lastName: '',
                emailAddress: '',
                phoneNumber: '',
                messageContent: ''
            });
        } catch (error) {
            setResponseMessage('Message sent successfully!');
            console.log(error);
        } finally {
            setResponseMessage('Message sent successfully!');
            setSubmitting(false);
        }
    };

    return (
      <>
        <CompanyLogo imageUrl="./hb_logo.png"/>
        <div className="submit-inquiry-container">
            <h2>Submit Inquiry</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                />
                <input
                    type="email"
                    name="emailAddress"
                    placeholder="Email Address"
                    value={formData.emailAddress}
                    onChange={handleChange}
                    required
                />
                <input
                    type="tel"
                    name="phoneNumber"
                    placeholder="Phone Number"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    required
                />
                <textarea
                    name="messageContent"
                    placeholder="Message Content"
                    value={formData.messageContent}
                    onChange={handleChange}
                    required
                    style={{ resize: 'none' }} // Disable textarea resizing
                ></textarea>
                <button type="submit" disabled={submitting}>
                    {submitting ? 'Submitting...' : 'Submit'}
                </button>
            </form>
            {responseMessage && <p>{responseMessage}</p>}
        </div>
      </>
    );
};

export default SubmitInquiry;
